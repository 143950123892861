import React, { useState, useEffect } from "react";

// Backend config
const apiBaseURL = process.env.REACT_APP_API_URL;

export default function MyEmails() {
  const [myEmails, setMyEmails] = useState([]);
  const [userInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || {}
  );
  const [emailTemplate, setEmailTemplate] = useState("");

  const fetchMyEmails = async (token = null) => {
    try {
      const response = await fetch(apiBaseURL + "my-emails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token || userInfo.token}`,
        },
      });
      const response_json = await response.json();
      setMyEmails(response_json.data?.user_requests || []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchMyEmails();
  }, []);
  console.log(myEmails);

  return (
    <>
      <h1 className="text-xl text-gray-700 font-bold mb-5">My Emails</h1>
      <div className="flex flex-wrap gap-6">
        {myEmails.map((email, index) => {
          console.log(email);
          return (
            <div
              key={index}
              className="block rounded-lg bg-white w-96 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 p-6"
            >
              <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                {email.prompt}
              </p>
              <button
                type="button"
                onClick={() =>
                  setEmailTemplate(
                    email.template
                      .replace(/\n/g, "<br />")
                      .replace("<br /><br />", "")
                  )
                }
                className="m-auto inline-block px-6 py-2.5 font-medium text-xs leading-tight rounded shadow-md transition duration-150 ease-in-out mt-1 bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
              >
                View
              </button>
            </div>
          );
        })}
      </div>
      {emailTemplate !== "" && (
        <div className="fixed top-20 w-full">
          <div className="top-10 z-50 p-4 overflow-x-hidden overflow-y-auto h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-full max-w-2xl max-h-full m-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 h-96 overflow-auto">
                  <span
                    className="text-base leading-relaxed text-gray-500 dark:text-gray-400"
                    dangerouslySetInnerHTML={{ __html: emailTemplate }}
                  ></span>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 justify-center">
                  <button
                    type="button"
                    onClick={() => setEmailTemplate("")}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
