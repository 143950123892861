import React, { useState } from "react";
import { AllBlogs } from "../constants";

export default function Blogs() {
  const [blogs] = useState(AllBlogs || []);

  return (
    <>
      <h1 className="text-xl text-gray-700 font-bold mb-5">Blogs</h1>
      <div className="grid grid-cols-2 gap-4">
        {blogs.map((blog, index) => (
        <div key={index}>
            <a target="_blank" rel="noreferrer" href={blog['post']['mediumUrl']} className="flex gap-1">
            <img src={`https://miro.medium.com/v2/da:true/resize:fill:160:107/${blog['post']['previewImage']['id']}`} />
            <div className="flex flex-col gap-1">
                <span className="font-bold">{blog['post']['title']}</span>
                <span>{blog['post']['extendedPreviewContent']['subtitle']}</span>
            </div>
            </a>
        </div>
        ))}
        </div>
    </>
  );
}
