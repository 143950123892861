export const AIBlogs = [
    {
        "post": {
            "id": "323d753a6aa1",
            "title": "OpenAI’s Sora: A Cinematic Leap in Generative Video Technology",
            "previewImage": {
                "id": "1*Nv25s3GB5n3LBn61ViWhaQ.gif",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "Revolutionary generative video model showcases Hollywood-quality clips, marking a significant milestone in the evolution of artificial…",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": false,
            "pinnedAt": 0,
            "firstPublishedAt": 1708030037195,
            "latestPublishedAt": 1708030170543,
            "creator": {
                "id": "24fe48448540",
                "name": "Zamal",
                "__typename": "User",
                "username": "zamalbabar",
                "mediumMemberAt": 0,
                "socialStats": {
                    "followerCount": 7,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": null,
                "hasSubdomain": false,
                "bio": "Solving tech puzzles with humor and curiosity. I promise, I won't make you read boring manuals. Let's geek out together! 💻😄",
                "imageId": "1*M9p7oVyiavmP04TsN2UzBw.jpeg",
                "membership": null
            },
            "isPublished": true,
            "mediumUrl": "https://medium.com/@zamalbabar/openais-sora-a-cinematic-leap-in-generative-video-technology-323d753a6aa1",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 0,
                "__typename": "PostResponses"
            },
            "visibility": "PUBLIC",
            "clapCount": 26,
            "pendingCollection": null,
            "statusForCollection": null,
            "readingTime": 2.2172955974842767,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "openais-sora-a-cinematic-leap-in-generative-video-technology-323d753a6aa1"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "9063bb56b680",
            "title": "The Emergent Field of Planetary Sapience and AI: The New Vanguard of Evolutionary Intelligence",
            "previewImage": {
                "id": "1*APuRFcf8pqJ3n1sTeL0odQ.png",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "Integrating Technology and Wisdom: Rethinking the Future of Planetary Intelligence in the Age of AI",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": true,
            "pinnedAt": 0,
            "firstPublishedAt": 1706444464436,
            "latestPublishedAt": 1706444464436,
            "creator": {
                "id": "6d19838b685f",
                "name": "Jeffrey Anthony",
                "__typename": "User",
                "username": "revivingvirtue",
                "mediumMemberAt": 1695477137000,
                "socialStats": {
                    "followerCount": 190,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": null,
                "hasSubdomain": false,
                "bio": "Engaging with Pragmatism, interpreting art and music through ethical lenses, exploring language's nuance, and engaging with heterodox economics.",
                "imageId": "1*gMFw5OV6bGsk0Iik0-CsEg.png",
                "membership": {
                    "tier": "FRIEND",
                    "__typename": "Membership",
                    "id": "81c55a8ec478"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://medium.com/@revivingvirtue/the-emergent-field-of-planetary-sapience-and-ai-the-new-vanguard-of-evolutionary-intelligence-9063bb56b680",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 6,
                "__typename": "PostResponses"
            },
            "visibility": "LOCKED",
            "clapCount": 153,
            "pendingCollection": null,
            "statusForCollection": null,
            "readingTime": 14.812578616352202,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "the-emergent-field-of-planetary-sapience-and-ai-the-new-vanguard-of-evolutionary-intelligence-9063bb56b680"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "1979a8ac4279",
            "title": "Will AI Replace All Coders?",
            "previewImage": {
                "id": "1*lCjAWYYYbfbqBTB4fQ4wgA.jpeg",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "Will AI take over all coding?",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": false,
            "pinnedAt": 0,
            "firstPublishedAt": 1707427901338,
            "latestPublishedAt": 1707427901338,
            "creator": {
                "id": "deedc76c1c2",
                "name": "Peter Diamandis",
                "__typename": "User",
                "username": "peterhdiamandis",
                "mediumMemberAt": 0,
                "socialStats": {
                    "followerCount": 53995,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": {
                    "live": {
                        "domain": "peterhdiamandis.medium.com",
                        "__typename": "CustomDomain"
                    },
                    "__typename": "CustomDomainState"
                },
                "hasSubdomain": true,
                "bio": "Passionate about innovation and creating a world of Abundance. Companies: XPRIZE, Singularity University, Planetary Resources, Human Longevity Inc.",
                "imageId": "0*VPf9MWRmXY84aP1t.jpeg",
                "membership": null
            },
            "isPublished": true,
            "mediumUrl": "https://peterhdiamandis.medium.com/will-ai-replace-all-coders-1979a8ac4279",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 51,
                "__typename": "PostResponses"
            },
            "visibility": "PUBLIC",
            "clapCount": 1648,
            "pendingCollection": null,
            "statusForCollection": null,
            "readingTime": 3.958490566037736,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "will-ai-replace-all-coders-1979a8ac4279"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "61c1e9572e78",
            "title": "The near supernatural state of AI. The Lady in Red. Study of OpenAI’s latest ‘Sora’ video.",
            "previewImage": {
                "id": "1*0iBbgeTvnCgtzS5Vk1bBug.png",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "You must check out this particular OpenAI gen AI video from today’s announcement of their new coming-soon AI service ‘Sora’.",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": true,
            "pinnedAt": 0,
            "firstPublishedAt": 1708064821037,
            "latestPublishedAt": 1708072554581,
            "creator": {
                "id": "66b31853c81",
                "name": "Paul Pallaghy, PhD",
                "__typename": "User",
                "username": "paul.k.pallaghy",
                "mediumMemberAt": 1573682166572,
                "socialStats": {
                    "followerCount": 8957,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": null,
                "hasSubdomain": false,
                "bio": "PhD Physicist / AI engineer / Biophysicist / Futurist into global good, AI, startups, EVs, green tech, space, biomed | Founder Pretzel Technologies Melbourne AU",
                "imageId": "1*vO0seLpXCosFXnSF_OSTqA.png",
                "membership": {
                    "tier": "MEMBER",
                    "__typename": "Membership",
                    "id": "39bd25862fa0"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://medium.com/@paul.k.pallaghy/the-near-supernatural-state-of-gen-ai-61c1e9572e78",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 2,
                "__typename": "PostResponses"
            },
            "visibility": "LOCKED",
            "clapCount": 174,
            "pendingCollection": null,
            "statusForCollection": null,
            "readingTime": 1.8927672955974844,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "the-near-supernatural-state-of-gen-ai-61c1e9572e78"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "0aafcdf75895",
            "title": "Nvidia: the sky’s the limit",
            "previewImage": {
                "id": "1*MmPaTf33Jt3dtpo75lSMcg.jpeg",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": "IMAGE: The NVIDIA Endeavor building in its corporate headquarters in Santa Clara, California"
            },
            "extendedPreviewContent": {
                "subtitle": "Nvidia’s valuation, which since late 2022 has skyrocketed, surpassed Amazon’s this week, and could be about to overtake Alphabet to become…",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": true,
            "pinnedAt": 0,
            "firstPublishedAt": 1707940806780,
            "latestPublishedAt": 1707992909011,
            "creator": {
                "id": "acfc0d77aeef",
                "name": "Enrique Dans",
                "__typename": "User",
                "username": "edans",
                "mediumMemberAt": 1490606895000,
                "socialStats": {
                    "followerCount": 61253,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": {
                    "live": {
                        "domain": "edans.medium.com",
                        "__typename": "CustomDomain"
                    },
                    "__typename": "CustomDomainState"
                },
                "hasSubdomain": true,
                "bio": "Professor of Innovation at IE Business School and blogger (in English here and in Spanish at enriquedans.com)",
                "imageId": "2*rYxmtnMlMLrGyIc30itIvw.jpeg",
                "membership": {
                    "tier": "FRIEND",
                    "__typename": "Membership",
                    "id": "5d93811a5580"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://medium.com/enrique-dans/nvidia-the-skys-the-limit-0aafcdf75895",
            "collection": {
                "id": "f41f22e4979f",
                "slug": "enrique-dans",
                "__typename": "Collection",
                "name": "Enrique Dans",
                "domain": null,
                "description": "On the effects of technology and innovation on people, companies and society (writing in Spanish at enriquedans.com since 2003)",
                "subscriberCount": 8935,
                "avatar": {
                    "id": "1*nUlo-8ud7_ntTWQUqMLKuA.jpeg",
                    "__typename": "ImageMetadata"
                }
            },
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 9,
                "__typename": "PostResponses"
            },
            "visibility": "LOCKED",
            "clapCount": 557,
            "pendingCollection": null,
            "statusForCollection": "APPROVED",
            "readingTime": 1.9018867924528302,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "nvidia-the-skys-the-limit-0aafcdf75895"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "3d34050efb71",
            "title": "The Math Behind K-Nearest Neighbors",
            "previewImage": {
                "id": "0*AGPTGho6P9vJFvUh",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "Why is KNN one of the most popular machine learning algorithm? Let’s understand it by diving into its math, and building it from scratch.",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": false,
            "pinnedAt": 0,
            "firstPublishedAt": 1707236991095,
            "latestPublishedAt": 1707865311146,
            "creator": {
                "id": "c24a3d106811",
                "name": "Cristian Leo",
                "__typename": "User",
                "username": "cristianleo120",
                "mediumMemberAt": 1700008222000,
                "socialStats": {
                    "followerCount": 2281,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": null,
                "hasSubdomain": false,
                "bio": "A Data Scientist with a passion about recreating all the popular machine learning algorithm from scratch.",
                "imageId": "0*vXUpsKuv7A7DlqP0",
                "membership": {
                    "tier": "MEMBER",
                    "__typename": "Membership",
                    "id": "5943fe884d89"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://towardsdatascience.com/the-math-behind-knn-3d34050efb71",
            "collection": {
                "id": "7f60cf5620c9",
                "slug": "towards-data-science",
                "__typename": "Collection",
                "name": "Towards Data Science",
                "domain": "towardsdatascience.com",
                "description": "Your home for data science. A Medium publication sharing concepts, ideas and codes.",
                "subscriberCount": 683864,
                "avatar": {
                    "id": "1*CJe3891yB1A1mzMdqemkdg.jpeg",
                    "__typename": "ImageMetadata"
                }
            },
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 4,
                "__typename": "PostResponses"
            },
            "visibility": "PUBLIC",
            "clapCount": 924,
            "pendingCollection": null,
            "statusForCollection": "APPROVED",
            "readingTime": 17.89245283018868,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "the-math-behind-knn-3d34050efb71"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "44b075315f27",
            "title": "Google has integrated Gemini into BigQuery",
            "previewImage": {
                "id": "1*mUHNlVyZHvfIADUwvL648g.jpeg",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "How you can now use BigQuery with Gemini & Vertex AI",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": true,
            "pinnedAt": 0,
            "firstPublishedAt": 1708076272976,
            "latestPublishedAt": 1708076272976,
            "creator": {
                "id": "2696f801a31a",
                "name": "Christianlauer",
                "__typename": "User",
                "username": "christianlauer90",
                "mediumMemberAt": 1585216353763,
                "socialStats": {
                    "followerCount": 7653,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": {
                    "live": {
                        "domain": "christianlauer90.medium.com",
                        "__typename": "CustomDomain"
                    },
                    "__typename": "CustomDomainState"
                },
                "hasSubdomain": true,
                "bio": "Big Data Enthusiast based in Hamburg and Kiel. Thankful if you would support my writing via: https://christianlauer90.medium.com/membership",
                "imageId": "1*el0BA70BDU6wLvgrlTfEkA.jpeg",
                "membership": {
                    "tier": "MEMBER",
                    "__typename": "Membership",
                    "id": "ab3b27f9212e"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://christianlauer90.medium.com/google-has-integrated-gemini-into-bigquery-44b075315f27",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 0,
                "__typename": "PostResponses"
            },
            "visibility": "LOCKED",
            "clapCount": 10,
            "pendingCollection": {
                "id": "374149271e27",
                "creator": {
                    "id": "dd3942a5498a",
                    "__typename": "User"
                },
                "name": "Technology Hits",
                "__typename": "Collection"
            },
            "statusForCollection": "PENDING",
            "readingTime": 1.8113207547169812,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "google-has-integrated-gemini-into-bigquery-44b075315f27"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "1f62a6cbdaef",
            "title": "Run A Small Language Model (SLM) Local & Offline",
            "previewImage": {
                "id": "1*9EaS5q1U-uYJ25wQ5FBxMQ.png",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "One notable advantage of SLMs are their flexibility in deployment — they can be run locally or offline, providing users with greater…",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": false,
            "pinnedAt": 0,
            "firstPublishedAt": 1707900374312,
            "latestPublishedAt": 1707900374312,
            "creator": {
                "id": "b0fbe613be9d",
                "name": "Cobus Greyling",
                "__typename": "User",
                "username": "cobusgreyling",
                "mediumMemberAt": 1564745719000,
                "socialStats": {
                    "followerCount": 16087,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": {
                    "live": {
                        "domain": "cobusgreyling.medium.com",
                        "__typename": "CustomDomain"
                    },
                    "__typename": "CustomDomainState"
                },
                "hasSubdomain": true,
                "bio": "I explore and write about all things at the intersection of AI & language; LLMs/NLP/NLU, Chat/Voicebots, CCAI. www.cobusgreyling.com",
                "imageId": "1*nzfAEuujMN0s-aK6R7RcNg.jpeg",
                "membership": {
                    "tier": "MEMBER",
                    "__typename": "Membership",
                    "id": "7b250337c1c6"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://cobusgreyling.medium.com/run-a-small-language-model-slm-local-offline-1f62a6cbdaef",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 0,
                "__typename": "PostResponses"
            },
            "visibility": "PUBLIC",
            "clapCount": 233,
            "pendingCollection": null,
            "statusForCollection": null,
            "readingTime": 5.612264150943396,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "run-a-small-language-model-slm-local-offline-1f62a6cbdaef"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "0701ee98768f",
            "title": "Visualize  your RAG Data — EDA for Retrieval-Augmented Generation",
            "previewImage": {
                "id": "1*tRjj5MdSlD1_ClUxXkS3VA.gif",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "How to use UMAP dimensionality reduction for Embeddings to show  Questions, Answers and their relationships to source documents with…",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": false,
            "pinnedAt": 0,
            "firstPublishedAt": 1707387565081,
            "latestPublishedAt": 1708072687922,
            "creator": {
                "id": "a8078cb720f3",
                "name": "Markus Stoll",
                "__typename": "User",
                "username": "markus.stoll",
                "mediumMemberAt": 1686688242000,
                "socialStats": {
                    "followerCount": 1312,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": null,
                "hasSubdomain": false,
                "bio": "CTO, Co-Founder at Renumics | 🤖 unstructured ML data | 📊 interactive ML data visualization | 🔍 ML data exploration",
                "imageId": "1*-8lOzTMVRmfmOj5YkIb9Rw.jpeg",
                "membership": {
                    "tier": "MEMBER",
                    "__typename": "Membership",
                    "id": "51c5029537d4"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://itnext.io/visualize-your-rag-data-eda-for-retrieval-augmented-generation-0701ee98768f",
            "collection": {
                "id": "5b301f10ddcd",
                "slug": "itnext",
                "__typename": "Collection",
                "name": "ITNEXT",
                "domain": "itnext.io",
                "description": "ITNEXT is a platform for IT developers & software engineers to share knowledge, connect, collaborate, learn and experience next-gen technologies.",
                "subscriberCount": 54692,
                "avatar": {
                    "id": "1*yAqDFIFA5F_NXalOJKz4TA.png",
                    "__typename": "ImageMetadata"
                }
            },
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 2,
                "__typename": "PostResponses"
            },
            "visibility": "PUBLIC",
            "clapCount": 787,
            "pendingCollection": null,
            "statusForCollection": "APPROVED",
            "readingTime": 8.560377358490566,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "visualize-your-rag-data-eda-for-retrieval-augmented-generation-0701ee98768f"
        },
        "__typename": "TagFeedItem"
    },
    {
        "post": {
            "id": "34563debd89f",
            "title": "Introducing Sora: OpenAI’s Groundbreaking Tool Transforms Text into Instant Video Magic",
            "previewImage": {
                "id": "1*ZfozHGnLpnf9DUXA6nmSuA@2x.jpeg",
                "__typename": "ImageMetadata",
                "focusPercentX": null,
                "focusPercentY": null,
                "alt": null
            },
            "extendedPreviewContent": {
                "subtitle": "Sora is a diffusion model, which generates a video by starting off with one that looks like static noise and gradually transforms it by…",
                "__typename": "PreviewContent",
                "isFullContent": false
            },
            "__typename": "Post",
            "isLocked": false,
            "pinnedAt": 0,
            "firstPublishedAt": 1708058813355,
            "latestPublishedAt": 1708058813355,
            "creator": {
                "id": "54fc81868959",
                "name": "Alphatu",
                "__typename": "User",
                "username": "Alphatue",
                "mediumMemberAt": 1680046780000,
                "socialStats": {
                    "followerCount": 10097,
                    "__typename": "SocialStats"
                },
                "verifications": {
                    "isBookAuthor": false,
                    "__typename": "VerifiedInfo"
                },
                "customDomainState": null,
                "hasSubdomain": false,
                "bio": "Writer & Researcher | Microsoft MVP | Author of 2 Books ｜Lecturer of PKU/Upenn | Twitter:@Alphatu4 | Arabic Learner | Believe in the power of deep thinking",
                "imageId": "1*4elYVsYdGCohztlAPSi8XA.jpeg",
                "membership": {
                    "tier": "MEMBER",
                    "__typename": "Membership",
                    "id": "3b9b88682cf8"
                }
            },
            "isPublished": true,
            "mediumUrl": "https://medium.com/@Alphatue/introducing-sora-openais-groundbreaking-tool-transforms-text-into-instant-video-magic-34563debd89f",
            "collection": null,
            "isLimitedState": false,
            "allowResponses": true,
            "postResponses": {
                "count": 0,
                "__typename": "PostResponses"
            },
            "visibility": "PUBLIC",
            "clapCount": 0,
            "pendingCollection": null,
            "statusForCollection": null,
            "readingTime": 4.263207547169811,
            "sequence": null,
            "isSeries": false,
            "uniqueSlug": "introducing-sora-openais-groundbreaking-tool-transforms-text-into-instant-video-magic-34563debd89f"
        },
        "__typename": "TagFeedItem"
    },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 84,
        "moduleSourceEncoding": null,
        "post": {
          "id": "00700244f58a",
          "title": "Get Started With Google Gemini Pro Using Python in 5 Minutes",
          "previewImage": {
            "id": "1*QMr0ird6s2QwtwxN_YAARQ.png",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "Access powerful Large Language Model Capabilities for Free!",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": false,
          "pinnedAt": 0,
          "firstPublishedAt": 1708012960318,
          "latestPublishedAt": 1708012960318,
          "creator": {
            "id": "6278d12b0682",
            "name": "Dipanjan (DJ) Sarkar",
            "__typename": "User",
            "username": "djsarkar",
            "mediumMemberAt": 0,
            "socialStats": {
              "followerCount": 10619,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": true,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "djsarkar.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "Data Science Lead. Connect at https://www.linkedin.com/in/dipanjans/",
            "imageId": "1*Wy0cxXZpX-FrMWeXsqOOpg.png",
            "membership": null
          },
          "isPublished": true,
          "mediumUrl": "https://pub.towardsai.net/get-started-with-google-gemini-pro-using-python-in-5-minutes-00700244f58a",
          "collection": {
            "id": "98111c9905da",
            "slug": "towards-artificial-intelligence",
            "__typename": "Collection",
            "name": "Towards AI",
            "domain": "pub.towardsai.net",
            "description": "The leading AI community and content platform focused on making AI accessible to all",
            "subscriberCount": 34638,
            "avatar": {
              "id": "1*JyIThO-cLjlChQLb6kSlVQ.png",
              "__typename": "ImageMetadata"
            }
          },
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 0,
            "__typename": "PostResponses"
          },
          "visibility": "PUBLIC",
          "clapCount": 78,
          "pendingCollection": null,
          "statusForCollection": "APPROVED",
          "readingTime": 6.237735849056604,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "get-started-with-google-gemini-pro-using-python-in-5-minutes-00700244f58a"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 107,
        "moduleSourceEncoding": null,
        "post": {
          "id": "e1c160acb9c9",
          "title": "Tabula Rasa: not enough data? Generate them!",
          "previewImage": {
            "id": "0*ATFOqMzROD5bt66l",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": "generative AI tabular data"
          },
          "extendedPreviewContent": {
            "subtitle": "How you can apply generative AI to tabular data",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": true,
          "pinnedAt": 0,
          "firstPublishedAt": 1707880482410,
          "latestPublishedAt": 1707880482410,
          "creator": {
            "id": "f1a08d9452cd",
            "name": "Salvatore Raieli",
            "__typename": "User",
            "username": "salvatore-raieli",
            "mediumMemberAt": 0,
            "socialStats": {
              "followerCount": 10469,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "salvatore-raieli.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "Senior data scientist | about science, machine learning, and AI. Top writer in Artificial Intelligence",
            "imageId": "1*cs7O1sBNbybTazY4AtBwig.jpeg",
            "membership": null
          },
          "isPublished": true,
          "mediumUrl": "https://levelup.gitconnected.com/tabula-rasa-not-enough-data-generate-them-e1c160acb9c9",
          "collection": {
            "id": "5517fd7b58a6",
            "slug": "gitconnected",
            "__typename": "Collection",
            "name": "Level Up Coding",
            "domain": "levelup.gitconnected.com",
            "description": "Coding tutorials and news. The developer homepage gitconnected.com && skilled.dev && levelup.dev",
            "subscriberCount": 98122,
            "avatar": {
              "id": "1*5D9oYBd58pyjMkV_5-zXXQ.jpeg",
              "__typename": "ImageMetadata"
            }
          },
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 3,
            "__typename": "PostResponses"
          },
          "visibility": "LOCKED",
          "clapCount": 224,
          "pendingCollection": null,
          "statusForCollection": "APPROVED",
          "readingTime": 17.576415094339623,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "tabula-rasa-not-enough-data-generate-them-e1c160acb9c9"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 85,
        "moduleSourceEncoding": null,
        "post": {
          "id": "4f80821434c9",
          "title": "Tesla Is Being Smashed By Its Rivals In One Weird Way",
          "previewImage": {
            "id": "0*fpExF7iRHml7syZZ",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "Even cheap Chinese rivals are kicking their ass.",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": true,
          "pinnedAt": 0,
          "firstPublishedAt": 1707323484002,
          "latestPublishedAt": 1707429628687,
          "creator": {
            "id": "e43895122f94",
            "name": "Will Lockett",
            "__typename": "User",
            "username": "wlockett",
            "mediumMemberAt": 1591366308000,
            "socialStats": {
              "followerCount": 183445,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": true,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "wlockett.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "Independent journalist covering global politics, climate change and technology. Get articles early at www.planetearthandbeyond.co",
            "imageId": "1*V0qWMQ8V5_NaF9yUoHAdyg.jpeg",
            "membership": {
              "tier": "MEMBER",
              "__typename": "Membership",
              "id": "98cb2e289b44"
            }
          },
          "isPublished": true,
          "mediumUrl": "https://medium.com/predict/tesla-is-being-smashed-by-its-rivals-in-one-weird-way-4f80821434c9",
          "collection": {
            "id": "661161fab0d0",
            "slug": "predict",
            "__typename": "Collection",
            "name": "Predict",
            "domain": null,
            "description": "where the future is written",
            "subscriberCount": 28067,
            "avatar": {
              "id": "1*EetZyjDw-19wRRBzc6fSMA.png",
              "__typename": "ImageMetadata"
            }
          },
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 23,
            "__typename": "PostResponses"
          },
          "visibility": "LOCKED",
          "clapCount": 1464,
          "pendingCollection": null,
          "statusForCollection": "APPROVED",
          "readingTime": 5.158490566037736,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "tesla-is-being-smashed-by-its-rivals-in-one-weird-way-4f80821434c9"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 84,
        "moduleSourceEncoding": null,
        "post": {
          "id": "c06edaa78534",
          "title": "Demonstrate, Search, Predict (DSP) for LLMs",
          "previewImage": {
            "id": "1*54LE60qrXqdvlPMJWLPYNg.png",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "This study which is just over a year old from Stanford, makes for interesting reading and illustrates how far we have come over as short…",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": false,
          "pinnedAt": 0,
          "firstPublishedAt": 1708071500711,
          "latestPublishedAt": 1708071500711,
          "creator": {
            "id": "b0fbe613be9d",
            "name": "Cobus Greyling",
            "__typename": "User",
            "username": "cobusgreyling",
            "mediumMemberAt": 1564745719000,
            "socialStats": {
              "followerCount": 16089,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "cobusgreyling.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "I explore and write about all things at the intersection of AI & language; LLMs/NLP/NLU, Chat/Voicebots, CCAI. www.cobusgreyling.com",
            "imageId": "1*nzfAEuujMN0s-aK6R7RcNg.jpeg",
            "membership": {
              "tier": "MEMBER",
              "__typename": "Membership",
              "id": "7b250337c1c6"
            }
          },
          "isPublished": true,
          "mediumUrl": "https://cobusgreyling.medium.com/demonstrate-search-predict-dsp-for-llms-c06edaa78534",
          "collection": null,
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 1,
            "__typename": "PostResponses"
          },
          "visibility": "PUBLIC",
          "clapCount": 6,
          "pendingCollection": null,
          "statusForCollection": null,
          "readingTime": 4.494339622641509,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "demonstrate-search-predict-dsp-for-llms-c06edaa78534"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 107,
        "moduleSourceEncoding": null,
        "post": {
          "id": "cd062bd08611",
          "title": "The Authority of the Algorithms",
          "previewImage": {
            "id": "1*QHR7G_nE3oqfhjeEkiL1Cg.png",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "Algorithms pervade every aspect of our lives, they are ethereal, yet have real impact. How much authority shall we give them?",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": true,
          "pinnedAt": 0,
          "firstPublishedAt": 1707745013646,
          "latestPublishedAt": 1707852227711,
          "creator": {
            "id": "f21ac1ba763a",
            "name": "Giles Crouch | Digital Anthropologist",
            "__typename": "User",
            "username": "gilescrouch",
            "mediumMemberAt": 1549658667000,
            "socialStats": {
              "followerCount": 53390,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "gilescrouch.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "Digital Anthropologist | Chief Innovation Officer | I'm in WIRED, Forbes & National Geographic etc.",
            "imageId": "1*jY0pnzmX6PYeqM6qrTti9Q.png",
            "membership": {
              "tier": "FRIEND",
              "__typename": "Membership",
              "id": "baa4fc2abf27"
            }
          },
          "isPublished": true,
          "mediumUrl": "https://gilescrouch.medium.com/the-authority-of-the-algorithms-cd062bd08611",
          "collection": null,
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 4,
            "__typename": "PostResponses"
          },
          "visibility": "LOCKED",
          "clapCount": 482,
          "pendingCollection": null,
          "statusForCollection": null,
          "readingTime": 3.758490566037736,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "the-authority-of-the-algorithms-cd062bd08611"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 85,
        "moduleSourceEncoding": null,
        "post": {
          "id": "cc30c6aa6e16",
          "title": "Roadmap to Learn AI in 2024",
          "previewImage": {
            "id": "1*TQlEMeYwwWc38Wqm7EfCTg.png",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "A free curriculum for hackers and programmers to learn AI",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": false,
          "pinnedAt": 1707489622332,
          "firstPublishedAt": 1707488844852,
          "latestPublishedAt": 1708051511498,
          "creator": {
            "id": "9a24cc840494",
            "name": "Benedict Neo",
            "__typename": "User",
            "username": "benedictxneo",
            "mediumMemberAt": 0,
            "socialStats": {
              "followerCount": 6527,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "benedictxneo.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "data science",
            "imageId": "1*0-N4JrXpZG1KJfIrAutd3A.png",
            "membership": null
          },
          "isPublished": true,
          "mediumUrl": "https://medium.com/bitgrit-data-science-publication/a-roadmap-to-learn-ai-in-2024-cc30c6aa6e16",
          "collection": {
            "id": "dc5e7c512acf",
            "slug": "bitgrit-data-science-publication",
            "__typename": "Collection",
            "name": "bitgrit Data Science Publication",
            "domain": null,
            "description": "We’re democratizing AI with our online competition platform — bitgrit.net. On our publication, we publish only high-quality data science-related topics. Become a writer by emailing us at: info@bitgrit.net",
            "subscriberCount": 783,
            "avatar": {
              "id": "1*LXVzIjr1RytEURr4xxZyfg.png",
              "__typename": "ImageMetadata"
            }
          },
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 11,
            "__typename": "PostResponses"
          },
          "visibility": "PUBLIC",
          "clapCount": 913,
          "pendingCollection": null,
          "statusForCollection": "APPROVED",
          "readingTime": 9.904716981132074,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "a-roadmap-to-learn-ai-in-2024-cc30c6aa6e16"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 84,
        "moduleSourceEncoding": null,
        "post": {
          "id": "0384a11d916b",
          "title": "Precision, Recall, and Desirability",
          "previewImage": {
            "id": "",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "Search focuses on precision, recall, and desirability. AI mostly helps with the first two, which makes it possible to focus on the third.",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": false,
          "pinnedAt": 0,
          "firstPublishedAt": 1708022149248,
          "latestPublishedAt": 1708027064572,
          "creator": {
            "id": "5f6b5aba680",
            "name": "Daniel Tunkelang",
            "__typename": "User",
            "username": "dtunkelang",
            "mediumMemberAt": 0,
            "socialStats": {
              "followerCount": 6340,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "dtunkelang.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "High-Class Consultant.",
            "imageId": "0*-Mm-tInUos0gj_X8.jpg",
            "membership": null
          },
          "isPublished": true,
          "mediumUrl": "https://dtunkelang.medium.com/precision-recall-and-desirability-0384a11d916b",
          "collection": null,
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 0,
            "__typename": "PostResponses"
          },
          "visibility": "PUBLIC",
          "clapCount": 5,
          "pendingCollection": null,
          "statusForCollection": null,
          "readingTime": 6.90566037735849,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "precision-recall-and-desirability-0384a11d916b"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 107,
        "moduleSourceEncoding": null,
        "post": {
          "id": "e6ae3f5a997f",
          "title": "AI Research in Investing",
          "previewImage": {
            "id": "1*eHBtw6eJbWwBEPJ2vHQpdQ.jpeg",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "A Look At Recent Papers",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": true,
          "pinnedAt": 0,
          "firstPublishedAt": 1707721852046,
          "latestPublishedAt": 1707722667934,
          "creator": {
            "id": "4fdbc7cc65ca",
            "name": "Mikhail Mew",
            "__typename": "User",
            "username": "aiinvestor",
            "mediumMemberAt": 1605793950000,
            "socialStats": {
              "followerCount": 1164,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "aiinvestor.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "Researcher | Investor | Data Scientist | Curious Observer. Thoughts and insights from the confluence of investing and machine learning.",
            "imageId": "1*RzIfahNi_oWNyOVfVY18AQ.png",
            "membership": {
              "tier": "MEMBER",
              "__typename": "Membership",
              "id": "abc425e940ae"
            }
          },
          "isPublished": true,
          "mediumUrl": "https://medium.datadriveninvestor.com/ai-research-in-investing-e6ae3f5a997f",
          "collection": {
            "id": "32881626c9c9",
            "slug": "datadriveninvestor",
            "__typename": "Collection",
            "name": "DataDrivenInvestor",
            "domain": "medium.datadriveninvestor.com",
            "description": "empowerment through data, knowledge, and expertise. subscribe to DDIntel at https://ddintel.datadriveninvestor.com",
            "subscriberCount": 64229,
            "avatar": {
              "id": "1*2mBCfRUpdSYRuf9EKnhTDQ.png",
              "__typename": "ImageMetadata"
            }
          },
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 1,
            "__typename": "PostResponses"
          },
          "visibility": "LOCKED",
          "clapCount": 211,
          "pendingCollection": null,
          "statusForCollection": "APPROVED",
          "readingTime": 7.175786163522013,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "ai-research-in-investing-e6ae3f5a997f"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 85,
        "moduleSourceEncoding": null,
        "post": {
          "id": "ee9b0b0ef082",
          "title": "Speech to Text to Speech with AI Using Python — a How-To Guide",
          "previewImage": {
            "id": "0*Ai0UNZUQI8jW0nau",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "How to Create a Speech-to-Text-to-Speech Program",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": false,
          "pinnedAt": 0,
          "firstPublishedAt": 1707666478743,
          "latestPublishedAt": 1707978537247,
          "creator": {
            "id": "ce7969d594d",
            "name": "Naomi Kriger",
            "__typename": "User",
            "username": "naomikriger",
            "mediumMemberAt": 0,
            "socialStats": {
              "followerCount": 172,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": {
              "live": {
                "domain": "naomikriger.medium.com",
                "__typename": "CustomDomain"
              },
              "__typename": "CustomDomainState"
            },
            "hasSubdomain": true,
            "bio": "Software developer, tech blogger, and public speaker. Love foreign languages, chocolate, and sports",
            "imageId": "1*IXcoooR9WpE8XCrYp1SsPg.jpeg",
            "membership": null
          },
          "isPublished": true,
          "mediumUrl": "https://towardsdatascience.com/speech-to-text-to-speech-with-ai-using-python-a-how-to-guide-ee9b0b0ef082",
          "collection": {
            "id": "7f60cf5620c9",
            "slug": "towards-data-science",
            "__typename": "Collection",
            "name": "Towards Data Science",
            "domain": "towardsdatascience.com",
            "description": "Your home for data science. A Medium publication sharing concepts, ideas and codes.",
            "subscriberCount": 683867,
            "avatar": {
              "id": "1*CJe3891yB1A1mzMdqemkdg.jpeg",
              "__typename": "ImageMetadata"
            }
          },
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 7,
            "__typename": "PostResponses"
          },
          "visibility": "PUBLIC",
          "clapCount": 537,
          "pendingCollection": null,
          "statusForCollection": "APPROVED",
          "readingTime": 7.090566037735849,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "speech-to-text-to-speech-with-ai-using-python-a-how-to-guide-ee9b0b0ef082"
        },
        "__typename": "TagFeedItem"
      },
      {
        "feedId": "fab3d72d-6d34-4443-870e-519024e6cd1f",
        "reason": 84,
        "moduleSourceEncoding": null,
        "post": {
          "id": "d30c328653d3",
          "title": "Dud Fucking Info",
          "previewImage": {
            "id": "1*6PIY0lMCll2zkXdxIoWrLA.png",
            "__typename": "ImageMetadata",
            "focusPercentX": null,
            "focusPercentY": null,
            "alt": null
          },
          "extendedPreviewContent": {
            "subtitle": "",
            "__typename": "PreviewContent",
            "isFullContent": false
          },
          "__typename": "Post",
          "isLocked": false,
          "pinnedAt": 0,
          "firstPublishedAt": 1708039815473,
          "latestPublishedAt": 1708054618984,
          "creator": {
            "id": "2ed0c99b08e",
            "name": "Ben Walker",
            "__typename": "User",
            "username": "bensroom",
            "mediumMemberAt": 0,
            "socialStats": {
              "followerCount": 256,
              "__typename": "SocialStats"
            },
            "verifications": {
              "isBookAuthor": false,
              "__typename": "VerifiedInfo"
            },
            "customDomainState": null,
            "hasSubdomain": false,
            "bio": "",
            "imageId": "2*U-6TTjCwkuhr_w_vnRkZuw.jpeg",
            "membership": null
          },
          "isPublished": true,
          "mediumUrl": "https://medium.com/@bensroom/dud-fucking-info-d30c328653d3",
          "collection": null,
          "isLimitedState": false,
          "allowResponses": true,
          "postResponses": {
            "count": 0,
            "__typename": "PostResponses"
          },
          "visibility": "PUBLIC",
          "clapCount": 0,
          "pendingCollection": null,
          "statusForCollection": null,
          "readingTime": 4.564465408805032,
          "sequence": null,
          "isSeries": false,
          "uniqueSlug": "dud-fucking-info-d30c328653d3"
        },
        "__typename": "TagFeedItem"
      }
]

export const AllBlogs = [
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 84,
      "moduleSourceEncoding": null,
      "post": {
        "id": "b2b615fd0636",
        "title": "Unlocking Opportunities with Prompt Engineering for Smart Development: Bridging Minds and Machines",
        "previewImage": {
          "id": "1*oS8mcrCLMt57X6X6xMP8Iw.png",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "Discover the transformative impact of prompt engineering on human-AI interactions. Unveil how this emerging fusion of programming and…",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": false,
        "creator": {
          "id": "dbd8765f7817",
          "name": "Venkat Rangasamy",
          "__typename": "User",
          "username": "genengineerAI",
          "mediumMemberAt": 0,
          "socialStats": {
            "followerCount": 721,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": null,
          "hasSubdomain": false,
          "bio": "Software technologist, experience in leading, designing, architecting software products for humans & machines.",
          "imageId": "1*poBflVBb3PO2tYi65DNg2g.png",
          "membership": null
        },
        "isPublished": true,
        "mediumUrl": "https://medium.com/@genengineerAI/unlocking-opportunities-with-prompt-engineering-for-smart-development-bridging-minds-and-machines-b2b615fd0636",
        "collection": null,
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 0,
          "__typename": "PostResponses"
        },
        "visibility": "PUBLIC",
        "clapCount": 54,
        "firstPublishedAt": 1708919429199,
        "latestPublishedAt": 1708919429199,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": null,
        "readingTime": 5.550943396226415,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "unlocking-opportunities-with-prompt-engineering-for-smart-development-bridging-minds-and-machines-b2b615fd0636"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 107,
      "moduleSourceEncoding": null,
      "post": {
        "id": "6e27598eb023",
        "title": "Versioned Data Management System Design",
        "previewImage": {
          "id": "0*yHyOGWpaqrUOIMxr",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "Introducing a Reliable Way to Manage Your Critical Data",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": true,
        "creator": {
          "id": "a7cf331e878b",
          "name": "Yuchen Z.",
          "__typename": "User",
          "username": "yuchen52",
          "mediumMemberAt": 0,
          "socialStats": {
            "followerCount": 641,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "yuchen52.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "Engineer @ Meta",
          "imageId": "0*rNL_v86kWO84I0TE.jpg",
          "membership": null
        },
        "isPublished": true,
        "mediumUrl": "https://levelup.gitconnected.com/versioned-data-management-system-design-6e27598eb023",
        "collection": {
          "id": "5517fd7b58a6",
          "slug": "gitconnected",
          "__typename": "Collection",
          "name": "Level Up Coding",
          "domain": "levelup.gitconnected.com",
          "description": "Coding tutorials and news. The developer homepage gitconnected.com && skilled.dev && levelup.dev",
          "subscriberCount": 98525,
          "avatar": {
            "id": "1*5D9oYBd58pyjMkV_5-zXXQ.jpeg",
            "__typename": "ImageMetadata"
          }
        },
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 0,
          "__typename": "PostResponses"
        },
        "visibility": "LOCKED",
        "clapCount": 110,
        "firstPublishedAt": 1708472093740,
        "latestPublishedAt": 1708472093740,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": "APPROVED",
        "readingTime": 3.8056603773584907,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "versioned-data-management-system-design-6e27598eb023"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 85,
      "moduleSourceEncoding": null,
      "post": {
        "id": "567181b90e8c",
        "title": "Free E-BOOK on Design Patterns in use",
        "previewImage": {
          "id": "0*2LkJRxEjosYsn986.png",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "The concept of design patterns in software engineering was popularized in the early 1990s by the famous book “ Design Patterns: Elements of…",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": false,
        "creator": {
          "id": "4b2348de8b98",
          "name": "Dr Milan Milanović",
          "__typename": "User",
          "username": "techworldwithmilan",
          "mediumMemberAt": 0,
          "socialStats": {
            "followerCount": 8817,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": null,
          "hasSubdomain": false,
          "bio": "Author of the Tech World With Milan Newsletter: https://newsletter.techworld-with-milan.com/",
          "imageId": "1*wZUTkEo2bOKA8xyaQI8Bcg.png",
          "membership": null
        },
        "isPublished": true,
        "mediumUrl": "https://medium.com/@techworldwithmilan/how-to-select-a-design-pattern-567181b90e8c",
        "collection": null,
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 8,
          "__typename": "PostResponses"
        },
        "visibility": "PUBLIC",
        "clapCount": 587,
        "firstPublishedAt": 1708335403835,
        "latestPublishedAt": 1708863223208,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": null,
        "readingTime": 10.88679245283019,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "how-to-select-a-design-pattern-567181b90e8c"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 84,
      "moduleSourceEncoding": null,
      "post": {
        "id": "701ad4d9ce25",
        "title": "Standard JavaScript Objects That Every Developer Should Know",
        "previewImage": {
          "id": "1*Pvo8eu69Mb-d5ZpLy8vyHg.png",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "Use these inbuilt objects to write manageable, hardware-friendly codebases",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": true,
        "creator": {
          "id": "ce00b3e87ebf",
          "name": "Shalitha Suranga",
          "__typename": "User",
          "username": "shalithasuranga",
          "mediumMemberAt": 1690551381000,
          "socialStats": {
            "followerCount": 5086,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "shalithasuranga.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "Programmer | Author of Neutralinojs | Technical Writer",
          "imageId": "1*3sLPPPykpFXoPCr3OYlunw.jpeg",
          "membership": {
            "tier": "MEMBER",
            "__typename": "Membership",
            "id": "7b0bac7e2bf5"
          }
        },
        "isPublished": true,
        "mediumUrl": "https://levelup.gitconnected.com/standard-javascript-objects-that-every-developer-should-know-701ad4d9ce25",
        "collection": {
          "id": "5517fd7b58a6",
          "slug": "gitconnected",
          "__typename": "Collection",
          "name": "Level Up Coding",
          "domain": "levelup.gitconnected.com",
          "description": "Coding tutorials and news. The developer homepage gitconnected.com && skilled.dev && levelup.dev",
          "subscriberCount": 98525,
          "avatar": {
            "id": "1*5D9oYBd58pyjMkV_5-zXXQ.jpeg",
            "__typename": "ImageMetadata"
          }
        },
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 0,
          "__typename": "PostResponses"
        },
        "visibility": "LOCKED",
        "clapCount": 61,
        "firstPublishedAt": 1708891375062,
        "latestPublishedAt": 1708891375062,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": "APPROVED",
        "readingTime": 9.506603773584906,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "standard-javascript-objects-that-every-developer-should-know-701ad4d9ce25"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 107,
      "moduleSourceEncoding": null,
      "post": {
        "id": "e504200e66a6",
        "title": "Python Design Patterns Introduction",
        "previewImage": {
          "id": "1*dg_LmUgwFNx0CkiAHOJP0Q.png",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": true,
        "creator": {
          "id": "89e7f336018b",
          "name": "Tony",
          "__typename": "User",
          "username": "tonylixu",
          "mediumMemberAt": 1660089670000,
          "socialStats": {
            "followerCount": 21049,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "tonylixu.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "Senior Cloud Engineer",
          "imageId": "1*i6Nx0h7eAaN5TqlSt4OX_w.jpeg",
          "membership": {
            "tier": "MEMBER",
            "__typename": "Membership",
            "id": "dd86bebf0474"
          }
        },
        "isPublished": true,
        "mediumUrl": "https://tonylixu.medium.com/python-design-patterns-introduction-e504200e66a6",
        "collection": null,
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 0,
          "__typename": "PostResponses"
        },
        "visibility": "LOCKED",
        "clapCount": 165,
        "firstPublishedAt": 1708789016034,
        "latestPublishedAt": 1708789016034,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": null,
        "readingTime": 6.875471698113207,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "python-design-patterns-introduction-e504200e66a6"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 85,
      "moduleSourceEncoding": null,
      "post": {
        "id": "b05f3620b37c",
        "title": "Rust Has A Dark Side. Why Is It Hated So Much?",
        "previewImage": {
          "id": "1*Bl_pSwYjRrI-QLhz__tRKg.jpeg",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "Why Do So Many Programmers Hate It So Much?",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": true,
        "creator": {
          "id": "f10e9a50984a",
          "name": "Dr. Ashish Bamania",
          "__typename": "User",
          "username": "bamania-ashish",
          "mediumMemberAt": 1646912037000,
          "socialStats": {
            "followerCount": 16961,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": true,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "bamania-ashish.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "Doctor 🩺 | Self-Taught Software Developer 👨‍💻 | Author 📘 | AIIMS, New Delhi 👨‍🎓 | Subscribe to my newsletter here: https://bytesurgery.substack.com/",
          "imageId": "1*5R3sTJ1KZkaD9s6XmBNsQA@2x.jpeg",
          "membership": {
            "tier": "MEMBER",
            "__typename": "Membership",
            "id": "25f57198bb45"
          }
        },
        "isPublished": true,
        "mediumUrl": "https://levelup.gitconnected.com/rust-has-a-dark-side-b05f3620b37c",
        "collection": {
          "id": "5517fd7b58a6",
          "slug": "gitconnected",
          "__typename": "Collection",
          "name": "Level Up Coding",
          "domain": "levelup.gitconnected.com",
          "description": "Coding tutorials and news. The developer homepage gitconnected.com && skilled.dev && levelup.dev",
          "subscriberCount": 98525,
          "avatar": {
            "id": "1*5D9oYBd58pyjMkV_5-zXXQ.jpeg",
            "__typename": "ImageMetadata"
          }
        },
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 32,
          "__typename": "PostResponses"
        },
        "visibility": "LOCKED",
        "clapCount": 575,
        "firstPublishedAt": 1708102119542,
        "latestPublishedAt": 1708286317025,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": "APPROVED",
        "readingTime": 5.121698113207547,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "rust-has-a-dark-side-b05f3620b37c"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 84,
      "moduleSourceEncoding": null,
      "post": {
        "id": "d3c3acd58c8a",
        "title": "Frugality and threat modeling",
        "previewImage": {
          "id": "1*8PpTdCOb0N8Ehz6hyoIiFQ.png",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": "Relative cost of fixing defects"
        },
        "extendedPreviewContent": {
          "subtitle": "Headlines frequently highlight the imperative of cost reduction, often synonymous with workforce reductions, particularly within IT…",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": false,
        "creator": {
          "id": "06ec73747582",
          "name": "Kris InfoSec",
          "__typename": "User",
          "username": "krisinfosec",
          "mediumMemberAt": 1708950903000,
          "socialStats": {
            "followerCount": 1,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "krisinfosec.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "",
          "imageId": "",
          "membership": {
            "tier": "MEMBER",
            "__typename": "Membership",
            "id": "49293f3d7402"
          }
        },
        "isPublished": true,
        "mediumUrl": "https://krisinfosec.medium.com/frugality-and-threat-modeling-d3c3acd58c8a",
        "collection": null,
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 0,
          "__typename": "PostResponses"
        },
        "visibility": "PUBLIC",
        "clapCount": 1,
        "firstPublishedAt": 1708924314714,
        "latestPublishedAt": 1708924314714,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": null,
        "readingTime": 2.4603773584905664,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "frugality-and-threat-modeling-d3c3acd58c8a"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 107,
      "moduleSourceEncoding": null,
      "post": {
        "id": "187a3af55074",
        "title": "Favoring Integration Tests Over Unit Tests to Verify Correctness",
        "previewImage": {
          "id": "1*KxwyrP9whMutE_QIZVtpMQ.png",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "It only takes a little extra to inspire confidence in your system.",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": true,
        "creator": {
          "id": "7c7a43b3d9de",
          "name": "Nicklas Millard",
          "__typename": "User",
          "username": "nmillard",
          "mediumMemberAt": 1587445154000,
          "socialStats": {
            "followerCount": 5006,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "nmillard.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "I mostly write to \"future me\" sharing what I learn and my opinion on software development practices. youtube.com/@nmillard | open for contracts in May 2024.",
          "imageId": "1*qcSZO_sdkX3KaAL4alVqXQ.jpeg",
          "membership": {
            "tier": "MEMBER",
            "__typename": "Membership",
            "id": "56345a94ed52"
          }
        },
        "isPublished": true,
        "mediumUrl": "https://medium.com/mjukvare/favoring-integration-tests-over-unit-tests-to-verify-correctness-187a3af55074",
        "collection": {
          "id": "55dc9e217160",
          "slug": "mjukvare",
          "__typename": "Collection",
          "name": "Mjukvare Engineering",
          "domain": null,
          "description": "Follow this publication and delve into cutting-edge practices for crafting impeccable code and honing software development expertise. Boost your skills and stay ahead of the tech curve. Follow us now!",
          "subscriberCount": 8,
          "avatar": {
            "id": "1*ALsLAfrICY10Bld1d8K8vg.png",
            "__typename": "ImageMetadata"
          }
        },
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 6,
          "__typename": "PostResponses"
        },
        "visibility": "LOCKED",
        "clapCount": 184,
        "firstPublishedAt": 1708773778071,
        "latestPublishedAt": 1708773778071,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": "APPROVED",
        "readingTime": 3.4037735849056605,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "favoring-integration-tests-over-unit-tests-to-verify-correctness-187a3af55074"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 85,
      "moduleSourceEncoding": null,
      "post": {
        "id": "d1ffb9d9f042",
        "title": "How to Write Clean Code in Python",
        "previewImage": {
          "id": "0*OaQDhhQ1iMaAB0dw",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "Top takeaways from the book Clean Code",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": true,
        "creator": {
          "id": "5db2cf7aa0b7",
          "name": "Patrick Brus",
          "__typename": "User",
          "username": "brus-patrick63",
          "mediumMemberAt": 1577375629000,
          "socialStats": {
            "followerCount": 902,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": {
            "live": {
              "domain": "brus-patrick63.medium.com",
              "__typename": "CustomDomain"
            },
            "__typename": "CustomDomainState"
          },
          "hasSubdomain": true,
          "bio": "A machine learning (ml) engineer with a focus on engineering. Create ml pipelines to enable rapid value from data using ml. This also includes MLOps and DevOps.",
          "imageId": "1*McXHVsWBPuJOmNer7p_Zww.jpeg",
          "membership": {
            "tier": "MEMBER",
            "__typename": "Membership",
            "id": "83ff19ed0566"
          }
        },
        "isPublished": true,
        "mediumUrl": "https://towardsdatascience.com/how-to-write-clean-code-in-python-d1ffb9d9f042",
        "collection": {
          "id": "7f60cf5620c9",
          "slug": "towards-data-science",
          "__typename": "Collection",
          "name": "Towards Data Science",
          "domain": "towardsdatascience.com",
          "description": "Your home for data science. A Medium publication sharing concepts, ideas and codes.",
          "subscriberCount": 684699,
          "avatar": {
            "id": "1*CJe3891yB1A1mzMdqemkdg.jpeg",
            "__typename": "ImageMetadata"
          }
        },
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 3,
          "__typename": "PostResponses"
        },
        "visibility": "LOCKED",
        "clapCount": 548,
        "firstPublishedAt": 1708737353253,
        "latestPublishedAt": 1708737353253,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": "APPROVED",
        "readingTime": 20.996226415094338,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "how-to-write-clean-code-in-python-d1ffb9d9f042"
      },
      "__typename": "TagFeedItem"
    },
    {
      "feedId": "8c40b303-30c2-4497-b5aa-560210145328",
      "reason": 84,
      "moduleSourceEncoding": null,
      "post": {
        "id": "c5fcbec3314e",
        "title": "Qualcomm SDE-1 Interview Experience",
        "previewImage": {
          "id": "",
          "__typename": "ImageMetadata",
          "focusPercentX": null,
          "focusPercentY": null,
          "alt": null
        },
        "extendedPreviewContent": {
          "subtitle": "My Background:",
          "__typename": "PreviewContent",
          "isFullContent": false
        },
        "__typename": "Post",
        "isLocked": false,
        "creator": {
          "id": "a38d71d3a28e",
          "name": "Barath Natarajan",
          "__typename": "User",
          "username": "barath_natarajan",
          "mediumMemberAt": 0,
          "socialStats": {
            "followerCount": 15,
            "__typename": "SocialStats"
          },
          "verifications": {
            "isBookAuthor": false,
            "__typename": "VerifiedInfo"
          },
          "customDomainState": null,
          "hasSubdomain": false,
          "bio": "",
          "imageId": "1*9TMOOMf_4YWL-H0LfBaeTQ.jpeg",
          "membership": null
        },
        "isPublished": true,
        "mediumUrl": "https://medium.com/@barath_natarajan/qualcomm-sde-1-interview-experience-c5fcbec3314e",
        "collection": null,
        "isLimitedState": false,
        "allowResponses": true,
        "postResponses": {
          "count": 0,
          "__typename": "PostResponses"
        },
        "visibility": "PUBLIC",
        "clapCount": 26,
        "firstPublishedAt": 1708876697101,
        "latestPublishedAt": 1708879043491,
        "pinnedAt": 0,
        "pendingCollection": null,
        "statusForCollection": null,
        "readingTime": 1.7471698113207548,
        "sequence": null,
        "isSeries": false,
        "uniqueSlug": "qualcomm-sde-1-interview-experience-c5fcbec3314e"
      },
      "__typename": "TagFeedItem"
    },

  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 107,
    "moduleSourceEncoding": null,
    "post": {
      "id": "889f90dd70f5",
      "title": "Can Everyone Not Become A Manager, Please?",
      "previewImage": {
        "id": "0*NbXl6DCl2ijhZrKj",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "An open letter to software engineers, designers, and architects…",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "2053aaf853f5",
        "name": "Attila Vágó",
        "__typename": "User",
        "username": "attilavago",
        "mediumMemberAt": 1637502714000,
        "socialStats": {
          "followerCount": 10663,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": true,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": {
          "live": {
            "domain": "attilavago.medium.com",
            "__typename": "CustomDomain"
          },
          "__typename": "CustomDomainState"
        },
        "hasSubdomain": true,
        "bio": "Staff software engineer, tech writer, author and opinionated human. LEGO and Apple fan. Accessibility advocate. Life enthusiast. Living in Dublin, Ireland. ☘️",
        "imageId": "1*Ilzy6aGvG__n7QzdkiL41A.jpeg",
        "membership": {
          "tier": "FRIEND",
          "__typename": "Membership",
          "id": "535b497b3451"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://levelup.gitconnected.com/can-everyone-not-become-a-manager-please-889f90dd70f5",
      "collection": {
        "id": "5517fd7b58a6",
        "slug": "gitconnected",
        "__typename": "Collection",
        "name": "Level Up Coding",
        "domain": "levelup.gitconnected.com",
        "description": "Coding tutorials and news. The developer homepage gitconnected.com && skilled.dev && levelup.dev",
        "subscriberCount": 98525,
        "avatar": {
          "id": "1*5D9oYBd58pyjMkV_5-zXXQ.jpeg",
          "__typename": "ImageMetadata"
        }
      },
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 15,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 740,
      "firstPublishedAt": 1708603329238,
      "latestPublishedAt": 1708611869143,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": "APPROVED",
      "readingTime": 5.633962264150943,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "can-everyone-not-become-a-manager-please-889f90dd70f5"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 85,
    "moduleSourceEncoding": null,
    "post": {
      "id": "0498c9eae88e",
      "title": "What I learned from the book Software Architecture: The Hard Parts",
      "previewImage": {
        "id": "0*nYMUw-gpTBqWgYVD.png",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "I recently read the book “ Software Architecture: The Hard Parts” by Neal Ford, Mark, Richards, Pramod Sadalage & Zhamak Dehghani, and this…",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": false,
      "creator": {
        "id": "4b2348de8b98",
        "name": "Dr Milan Milanović",
        "__typename": "User",
        "username": "techworldwithmilan",
        "mediumMemberAt": 0,
        "socialStats": {
          "followerCount": 8817,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": null,
        "hasSubdomain": false,
        "bio": "Author of the Tech World With Milan Newsletter: https://newsletter.techworld-with-milan.com/",
        "imageId": "1*wZUTkEo2bOKA8xyaQI8Bcg.png",
        "membership": null
      },
      "isPublished": true,
      "mediumUrl": "https://medium.com/@techworldwithmilan/what-i-learned-from-the-software-architecture-the-hard-parts-0498c9eae88e",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 14,
        "__typename": "PostResponses"
      },
      "visibility": "PUBLIC",
      "clapCount": 1200,
      "firstPublishedAt": 1707409565668,
      "latestPublishedAt": 1707409739264,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 7.636792452830188,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "what-i-learned-from-the-software-architecture-the-hard-parts-0498c9eae88e"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 84,
    "moduleSourceEncoding": null,
    "post": {
      "id": "33b9ee0852ff",
      "title": "Build Beautiful GUI’s With Python In 2024",
      "previewImage": {
        "id": "1*XTmOKcQHJuUJ_gccpkTCHw.png",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "Welcome back! Python is one of my favorite programming languages! It’s also one of my favorite languages to build out GUI’s, so, let’s take…",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "c5f0ad9001fd",
        "name": "Manpreet Singh",
        "__typename": "User",
        "username": "preettheman",
        "mediumMemberAt": 1613316717000,
        "socialStats": {
          "followerCount": 4927,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": {
          "live": {
            "domain": "preettheman.medium.com",
            "__typename": "CustomDomain"
          },
          "__typename": "CustomDomainState"
        },
        "hasSubdomain": true,
        "bio": "Data Scientist / Engineer!",
        "imageId": "1*BRK_KhuIfExT7gBx5xZ75Q.jpeg",
        "membership": {
          "tier": "MEMBER",
          "__typename": "Membership",
          "id": "7a4f1ba7e2a3"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://preettheman.medium.com/build-beautiful-guis-with-python-in-2024-33b9ee0852ff",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 1,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 15,
      "firstPublishedAt": 1708901253999,
      "latestPublishedAt": 1708901253999,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 1.8,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "build-beautiful-guis-with-python-in-2024-33b9ee0852ff"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 107,
    "moduleSourceEncoding": null,
    "post": {
      "id": "8064f4d32e20",
      "title": "5 Python Coding Errors That Are Killing Your Speed (And How to Fix Them Today)",
      "previewImage": {
        "id": "1*oVWShYSECdQ8UUVlzhWu-Q.png",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": "A sleek cheetah running at top speed, symbolizing optimized Python code."
      },
      "extendedPreviewContent": {
        "subtitle": "Slow Python code? 5 easy fixes for instant speed-up.",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "404253426aec",
        "name": "Builescu Daniel",
        "__typename": "User",
        "username": "danielbuilescu",
        "mediumMemberAt": 1693918016000,
        "socialStats": {
          "followerCount": 8974,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": true,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": {
          "live": null,
          "__typename": "CustomDomainState"
        },
        "hasSubdomain": false,
        "bio": "Ex-Googler teaching Python, Shopify Liquid & Swift. Writing weekends to make you a pro coder!",
        "imageId": "1*UwuwDN6gR0RafdZzU2Wd6A.jpeg",
        "membership": {
          "tier": "FRIEND",
          "__typename": "Membership",
          "id": "2f53e3b5a9b"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://python.plainenglish.io/5-python-coding-errors-that-are-killing-your-speed-and-how-to-fix-them-today-8064f4d32e20",
      "collection": {
        "id": "78073def27b8",
        "slug": "python-in-plain-english",
        "__typename": "Collection",
        "name": "Python in Plain English",
        "domain": "python.plainenglish.io",
        "description": "New Python content every day. Follow to join our 3.5M+ monthly readers.",
        "subscriberCount": 17978,
        "avatar": {
          "id": "1*VA3oGfprJgj5fRsTjXp6fA@2x.png",
          "__typename": "ImageMetadata"
        }
      },
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 1,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 399,
      "firstPublishedAt": 1708704068275,
      "latestPublishedAt": 1708911788947,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": "APPROVED",
      "readingTime": 5.490566037735849,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "5-python-coding-errors-that-are-killing-your-speed-and-how-to-fix-them-today-8064f4d32e20"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 85,
    "moduleSourceEncoding": null,
    "post": {
      "id": "aecd1f2ac2a7",
      "title": "Low-code for large projects. It’s time.",
      "previewImage": {
        "id": "1*Weom1JxGAt4AEI1yGONKNQ.png",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "After reading this article, you’ll be convinced to use low-code for your company’s next business application.",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "af7eab694662",
        "name": "Maxime Topolov",
        "__typename": "User",
        "username": "maximetopolov",
        "mediumMemberAt": 1647618564000,
        "socialStats": {
          "followerCount": 2409,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": null,
        "hasSubdomain": false,
        "bio": "Entrepreneur. CEO of code.store. I write about tech, dev and projects management.",
        "imageId": "1*osPch_rCUoc0m-jxPtXWtg@2x.jpeg",
        "membership": {
          "tier": "MEMBER",
          "__typename": "Membership",
          "id": "3e018c3892fe"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://blog.devgenius.io/low-code-for-large-projects-its-time-aecd1f2ac2a7",
      "collection": {
        "id": "4e2c1156667e",
        "slug": "dev-genius",
        "__typename": "Collection",
        "name": "Dev Genius",
        "domain": "blog.devgenius.io",
        "description": "Coding, Tutorials, News, UX, UI and much more related to development",
        "subscriberCount": 19261,
        "avatar": {
          "id": "1*CvejhRq3NYsivxILYXEdfA.jpeg",
          "__typename": "ImageMetadata"
        }
      },
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 15,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 308,
      "firstPublishedAt": 1707922098061,
      "latestPublishedAt": 1708721793600,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": "APPROVED",
      "readingTime": 7.497169811320754,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "low-code-for-large-projects-its-time-aecd1f2ac2a7"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 84,
    "moduleSourceEncoding": null,
    "post": {
      "id": "bd1986c73719",
      "title": "Airbnb Microservice Architecture",
      "previewImage": {
        "id": "0*_ljVUZZKwYj9WVrV.jpeg",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "Jessica Tai, an engineering manager at Airbnb, recently talked at QCon about how Airbnb’s architecture changed over the years and followed…",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": false,
      "creator": {
        "id": "4b2348de8b98",
        "name": "Dr Milan Milanović",
        "__typename": "User",
        "username": "techworldwithmilan",
        "mediumMemberAt": 0,
        "socialStats": {
          "followerCount": 8817,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": null,
        "hasSubdomain": false,
        "bio": "Author of the Tech World With Milan Newsletter: https://newsletter.techworld-with-milan.com/",
        "imageId": "1*wZUTkEo2bOKA8xyaQI8Bcg.png",
        "membership": null
      },
      "isPublished": true,
      "mediumUrl": "https://medium.com/@techworldwithmilan/airbnb-microservice-architecture-bd1986c73719",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 1,
        "__typename": "PostResponses"
      },
      "visibility": "PUBLIC",
      "clapCount": 101,
      "firstPublishedAt": 1708796870349,
      "latestPublishedAt": 1708796870349,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 1.8339622641509434,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "airbnb-microservice-architecture-bd1986c73719"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 107,
    "moduleSourceEncoding": null,
    "post": {
      "id": "cf314946bb80",
      "title": "The 5 ChatGPT Prompts That Represent the State of Coding in 2024",
      "previewImage": {
        "id": "0*UoDf0WRwCyMSOE-K",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "“ChatGPT Write This Article for Me”",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "30834bcaf46a",
        "name": "The Secret Developer",
        "__typename": "User",
        "username": "tsecretdeveloper",
        "mediumMemberAt": 1690811976000,
        "socialStats": {
          "followerCount": 20907,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": null,
        "hasSubdomain": false,
        "bio": "A top software developer who has worked for some of the biggest tech companies (yes, that one too) reveals the stories behind Big Tech and software engineering.",
        "imageId": "1*dmbNkD5D-u45r44go_cf0g.png",
        "membership": {
          "tier": "MEMBER",
          "__typename": "Membership",
          "id": "57c798bfa3f2"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://medium.com/@tsecretdeveloper/the-5-chatgpt-prompts-that-represent-the-state-of-coding-in-2024-cf314946bb80",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 0,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 160,
      "firstPublishedAt": 1708642312959,
      "latestPublishedAt": 1708642312959,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 2.6641509433962267,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "the-5-chatgpt-prompts-that-represent-the-state-of-coding-in-2024-cf314946bb80"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 85,
    "moduleSourceEncoding": null,
    "post": {
      "id": "85139edee87d",
      "title": "I Built an App in 6 Hours that Makes $1,500/Mo",
      "previewImage": {
        "id": "1*5v_U1acnGcAMnvYWncEYYA.png",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "Copy my strategy!",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "b7bdb0fad1c4",
        "name": "Artturi Jalli",
        "__typename": "User",
        "username": "artturi-jalli",
        "mediumMemberAt": 1611727341000,
        "socialStats": {
          "followerCount": 7948,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": {
          "live": null,
          "__typename": "CustomDomainState"
        },
        "hasSubdomain": false,
        "bio": "Youtube: @jalliartturi to take your blog to the next level",
        "imageId": "1*LVOq8WJ5EscuX197508AzA.jpeg",
        "membership": {
          "tier": "MEMBER",
          "__typename": "Membership",
          "id": "4df644b8420d"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://medium.com/@artturi-jalli/i-built-an-app-in-6-hours-that-makes-1-500-mo-85139edee87d",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 140,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 11036,
      "firstPublishedAt": 1706017722251,
      "latestPublishedAt": 1707161468669,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 2.199056603773585,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "i-built-an-app-in-6-hours-that-makes-1-500-mo-85139edee87d"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 84,
    "moduleSourceEncoding": null,
    "post": {
      "id": "d001369171f0",
      "title": "Write meaningful technical CVs using skills matrices",
      "previewImage": {
        "id": "0*rhGJprLIdDYnp1pq.jpg",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "Through the years, I have encountered the same issue again and again when reading CVs. Skill lists never convey enough information…",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": false,
      "creator": {
        "id": "aff7cb7b141d",
        "name": "Balázs Kovács",
        "__typename": "User",
        "username": "kobalazs",
        "mediumMemberAt": 0,
        "socialStats": {
          "followerCount": 15,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": null,
        "hasSubdomain": false,
        "bio": "Software engineer, IT educator and team leader at Stylers Group",
        "imageId": "1*rfQCJ0Fm2HYN0hSsBcvmBQ.png",
        "membership": null
      },
      "isPublished": true,
      "mediumUrl": "https://medium.com/@kobalazs/write-meaningful-technical-cvs-using-skills-matrices-d001369171f0",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 0,
        "__typename": "PostResponses"
      },
      "visibility": "PUBLIC",
      "clapCount": 0,
      "firstPublishedAt": 1708945690566,
      "latestPublishedAt": 1708945690566,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 1.7575471698113208,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "write-meaningful-technical-cvs-using-skills-matrices-d001369171f0"
    },
    "__typename": "TagFeedItem"
  },
  {
    "feedId": "4b242de2-c94f-4bed-b199-e0aa604e6298",
    "reason": 107,
    "moduleSourceEncoding": null,
    "post": {
      "id": "062e05c44614",
      "title": "Linux — CPU Architecture and Kernel Components",
      "previewImage": {
        "id": "1*rU7oOX0ny0AYBBFouP9xCg.png",
        "__typename": "ImageMetadata",
        "focusPercentX": null,
        "focusPercentY": null,
        "alt": null
      },
      "extendedPreviewContent": {
        "subtitle": "",
        "__typename": "PreviewContent",
        "isFullContent": false
      },
      "__typename": "Post",
      "isLocked": true,
      "creator": {
        "id": "89e7f336018b",
        "name": "Tony",
        "__typename": "User",
        "username": "tonylixu",
        "mediumMemberAt": 1660089670000,
        "socialStats": {
          "followerCount": 21049,
          "__typename": "SocialStats"
        },
        "verifications": {
          "isBookAuthor": false,
          "__typename": "VerifiedInfo"
        },
        "customDomainState": {
          "live": {
            "domain": "tonylixu.medium.com",
            "__typename": "CustomDomain"
          },
          "__typename": "CustomDomainState"
        },
        "hasSubdomain": true,
        "bio": "Senior Cloud Engineer",
        "imageId": "1*i6Nx0h7eAaN5TqlSt4OX_w.jpeg",
        "membership": {
          "tier": "MEMBER",
          "__typename": "Membership",
          "id": "dd86bebf0474"
        }
      },
      "isPublished": true,
      "mediumUrl": "https://tonylixu.medium.com/linux-cpu-architecture-and-kernel-components-062e05c44614",
      "collection": null,
      "isLimitedState": false,
      "allowResponses": true,
      "postResponses": {
        "count": 0,
        "__typename": "PostResponses"
      },
      "visibility": "LOCKED",
      "clapCount": 281,
      "firstPublishedAt": 1708438344780,
      "latestPublishedAt": 1708438344780,
      "pinnedAt": 0,
      "pendingCollection": null,
      "statusForCollection": null,
      "readingTime": 8.222641509433961,
      "sequence": null,
      "isSeries": false,
      "uniqueSlug": "linux-cpu-architecture-and-kernel-components-062e05c44614"
    },
    "__typename": "TagFeedItem"
  },
  ...AIBlogs

  ]